<template>
  <div>
    <H5header :type="2"></H5header>
    <div class="top">
      <div class="titlebox">
        <div class="xian"></div>
        <div class="title">填写队员信息</div>
      </div>
    </div>
    <div v-for="(item, index) in info.info" :key="index">
      <div class="inputbox">
        <img src="../../../assets/image/project2.png" alt="" style="width: 13PX;height: 15PX;">
        <p style="margin-right: 33px;">组别</p>
        <el-input placeholder="请输入" :disabled="true" v-model="groupName"></el-input>
      </div>
      <div class="inputbox">
        <img src="../../../assets/image/group.png" alt="" style="width: 13PX;height: 15PX;">
        <p style="margin-right: 33px;">项目</p>
        <el-input placeholder="请输入" :disabled="true" v-model="projectName"></el-input>
      </div>
      <div class="inputbox" v-if="isOpenNotes">
        <img src="../../../assets/image/message.png" alt="" style="width: 13PX;height: 15PX;">
        <div class="Remark" style="margin-right: 0;color: #2A7AFF;" @click="getcertificate(notesRemark)">{{ notesRemark
          }}
        </div>
        <el-input placeholder="请输入" v-model="item.projectRemark" :disabled="disabled"></el-input>
      </div>
      <div class="inputbox" v-if="isOpenSportNotes">
        <img src="../../../assets/image/message.png" alt="" style="width: 13PX;height: 15PX;">
        <div class="Remark" style="margin-right: 0;color: #2A7AFF;" @click="getcertificate(sportNotesRemark)">{{
          sportNotesRemark }}</div>
        <el-input placeholder="请输入" v-model="item.sportsmanRemark" :disabled="disabled"></el-input>
      </div>
      <div class="inputbox">
        <img src="../../../assets/image/user.png" alt="" style="width: 13PX;height: 15PX;">
        <p>姓名</p>
        <span style="margin-right: 23px;">*</span>
        <el-input placeholder="请输入" v-model="item.sportsmanName" :disabled="disabled"></el-input>
        <div class="but" v-if="disabled">
          常用报名人
        </div>
        <div class="but" @click="getme(index)" v-else>
          常用报名人
        </div>
      </div>
      <div class="inputbox">
        <img src="../../../assets/image/gender.png" alt="" style="width: 13PX;height: 15PX;">
        <p>性别</p>
        <span style="margin-right: 28PX;">*</span>
        <a-select option-label-prop="label" placeholder="请选择" v-model="item.sportsmanSex" :disabled="disabled"
          allowClear style="width: 180PX;">
          <a-select-option v-for="items in gender" :key="items.value" :value="items.value" :label="items.label">
            <a-icon type="check" v-if="items.value === item.sportsmanSex" />
            {{ items.label }}
          </a-select-option>
        </a-select>
        <img src="../../../assets/image/more.png" style="margin-right: 12PX;z-index: 99;width: 6PX;height: 11PX;">
      </div>
      <div class="inputbox">
        <img src="../../../assets/image/ID.png" alt="" style="width: 15PX;height: 15PX;">
        <p>证件类型</p>
        <span style="margin-right: 3PX;">*</span>
        <a-select option-label-prop="label" placeholder="请选择" v-model="item.sportsmanCardType" :disabled="disabled"
          allowClear style="width: 180PX;">
          <a-select-option v-for="items in idCardType" :key="items.itemValue" :value="items.itemValue"
            :label="items.itemText">
            <a-icon type="check" v-if="items.itemValue === item.sportsmanCardType" />
            {{ items.itemText }}
          </a-select-option>
        </a-select>
        <img src="../../../assets/image/more.png" style="margin-right: 12PX;z-index: 99;width: 6PX;height: 11PX;">
      </div>
      <div class="inputbox">
        <img src="../../../assets/image/ID1.png" alt="" style="width: 15PX;height: 15PX;">
        <p>证件号码</p>
        <span>*</span>
        <el-input placeholder="请输入" v-model="item.sportsmanCardNumber" :disabled="disabled" @blur="getcardnum(index)"
          @input="formatIdCard(index)" maxlength="30"></el-input>
      </div>
      <div class="inputbox">
        <img src="../../../assets/image/dateofbirth.png" alt="" style="width: 13PX;height: 15PX;">
        <p>出生日期</p>
        <span>*</span>
        <el-date-picker v-model="item.sportsmanBirthday" :disabled="disabled" type="date" placeholder="请选择"
          format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 198px;">
        </el-date-picker>
        <img src="../../../assets/image/more.png"
          style="margin-right: 12PX;z-index: 99;width: 6PX;height: 11PX;margin-left: 0;">
      </div>
      <div class="inputbox">
        <img src="../../../assets/image/tel.png" alt="" style="width: 13PX;height: 15PX;">
        <p style="margin-left: 14PX;">手机号</p>
        <span style="margin-right: 10PX;">*</span>
        <el-input placeholder="请输入" v-model="item.sportsmanPhone" :disabled="disabled"></el-input>
      </div>
      <div class="inputbox" style="background: none;height: auto;">
        <img src="../../../assets/image/upload1.png" alt="" style="width: 13PX;height: 15PX;">
        <p style="margin-right: 18PX;">上传附件</p>
        <div v-if="!disabled">
          <el-upload class="upload-demo" :data="upData" :action="uploadUrl"
            :on-success="(response, file) => success(response, file, index)"
            :on-remove="(file, fileList) => removeFile(file, fileList, index)" multiple :limit="3" :headers="headers"
            :file-list="item.fileList" :disabled="disabled">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
        <div v-else>
          <el-link :underline="false" v-for="(ites, index) in item.fileList" :key="index" type="primary"
            style="margin-left: 10PX" @click="download(ites)">
            {{ ites.fileName }}
          </el-link>
        </div>
      </div>
      <div class="xians"></div>
    </div>

    <!-- <div class="radio" v-if="!disabled">
      <el-radio label="1" v-model="radio"></el-radio>
      <div>
        <p>已阅读同意<a class="jumpto" :href="VUE_APP_BASE_PRIVACYS" target="_blank">免责协议</a></p>
      </div>
    </div> -->
    <div style="height: 20PX;"></div>
    <div class="button" v-if="!disabled">
      <div class="save" @click="preservation">保存</div>
    </div>

    <div class="button2" v-if="!disabled">
      <div class="save" @click="Submit">提交</div>
    </div>


  </div>
</template>

<script>
import Vue from 'vue'
import Meta from 'vue-meta'
Vue.use(Meta)
import H5header from "../components/header2.vue"
export default {
  components: {
    H5header
  },
  props: {},
  data() {
    return {
      idCardType: [],
      gender: [{
        label: '男',
        value: '1'
      },
      {
        label: '女',
        value: '2'
      }],
      fileList: [],
      upData: {
        fileExtensionList: ["jpg", "png"],
      },
      uploadUrl: process.env.VUE_APP_BASE_API + '/api/upload',
      headers: {
        "token": this.$store.state.token
      },
      radio: '0',
      VUE_APP_BASE_PRIVACYS: process.env.VUE_APP_BASE_PRIVACYS,

      isOpenNotes: false,
      isOpenSportNotes: false,
      notesRemark: '',
      sportNotesRemark: '',

      info: {
        userId: this.$store.state.userid,
        matchId: "",
        settingsId: "",
        status: 0,
        info: []
      },

      disabled: false,
      groupName: '',
      projectName: ''
    };
  },
  computed: {},
  watch: {},
  methods: {
    getIdCardType() {
      this.$api.idCardType({ dictValue: '其他' }).then(res => {
        this.idCardType = res.data.data.result
      })
    },
    getBasicEnrollInfoByMatchId(id) {
      this.$api.getBasicEnrollInfoByMatchId(id).then(res => {
        this.isOpenNotes = res.data.data.result.isOpenNotes
        this.isOpenSportNotes = res.data.data.result.isOpenSportNotes
        this.notesRemark = res.data.data.result.notesRemark
        this.sportNotesRemark = res.data.data.result.sportNotesRemark
      })
    },
    userEnrollInfo(id) {
      this.$api.userEnrollInfo({ enrollId: id }).then(res => {
        res.data.data.result.info.forEach(item => {
          item.sportsmanName = this.$decrypt(item.sportsmanName);
          item.sportsmanCardNumber = this.$decrypt(item.sportsmanCardNumber);
          item.sportsmanPhone = this.$decrypt(item.sportsmanPhone);
          item.fileList.forEach(ress => {
            ress.name = ress.fileName
            ress.url = ress.filePath
          })
        })
        this.info = res.data.data.result
      })
    },
    success(response, file, index) {
      if (!this.info.info[index].fileList) {
        this.$set(this.info.info[index], 'fileList', []);
      }

      let newFile = {
        fileName: file.name,
        filePath: response.data.message,
        id: null,
        name: file.name,
        url: response.data.message,
      };

      let newFileList = [...this.info.info[index].fileList];
      newFileList.push(newFile);

      this.$set(this.info.info[index], 'fileList', newFileList);
    },
    removeFile(file, fileList, index) {
      const removeFileIndex = this.info.info[index].fileList.findIndex(
        item => item.fileName === file.name
      );
      if (removeFileIndex !== -1) {
        this.info.info[index].fileList.splice(removeFileIndex, 1);
      }
    },
    getme(row) {
      let data = {
        index: row,
        distinguish: 1,
        data: this.info,
        new: {}
      }
      localStorage.setItem('regsignup', JSON.stringify(data));

      this.$router.replace({
        path: '/h5/registernewlyaddedlist',
        query: {
          refresh: new Date().getTime(),
          id: this.$route.query.id,
          groupName: this.groupName,
          projectName: this.projectName
        },
      })

    },
    isChineseTextContains(text, target) {
      let regExp = new RegExp(target, 'g');
      return regExp.test(text);
    },
    // 验证身份证是否正确
    validateIdCard(idCard) {
      // 校验身份证号码长度
      if (idCard.length !== 15 && idCard.length !== 18) {
        return false
      }

      // 校验前面的数字部分
      const pattern = idCard.length === 15 ? /^\d{15}$/ : /^\d{17}[\dXx]$/;
      if (!pattern.test(idCard)) {
        return false
      }

      // 统一转换为 18 位身份证号码
      let idCard18 = idCard;
      if (idCard.length === 15) {
        idCard18 = this.convertTo18IdCard(idCard);
      }

      // 校验最后一位校验位
      const factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
      const checkSum = idCard18.slice(0, 17).split('').reduce((sum, digit, index) => {
        return sum + parseInt(digit) * factors[index];
      }, 0) % 11;
      const checkCode = '10X98765432'; // 校验码映射
      if (idCard18[17] !== checkCode[checkSum]) {
        return false
      }

      // 验证通过
      return true
    },
    // 验证身份证 出生日期 性别 
    getBirthdayAndGender(idCard) {
      // 校验身份证号码长度
      if (idCard.length !== 15 && idCard.length !== 18) {
        return null;
      }

      // 校验前面的数字部分
      const pattern = idCard.length === 15 ? /^\d{15}$/ : /^\d{17}[\dXx]$/;
      if (!pattern.test(idCard)) {
        return null;
      }

      // 统一转换为 18 位身份证号码
      if (idCard.length === 15) {
        idCard = this.convertTo18IdCard(idCard);
      }
      // 校验最后一位校验位
      const factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
      const checkSum = idCard.slice(0, 17).split('').reduce((sum, digit, index) => {
        return sum + parseInt(digit) * factors[index];
      }, 0) % 11;
      const checkCode = '10X98765432'; // 校验码映射
      if (idCard[17] !== checkCode[checkSum]) {
        return null;
      }

      // 提取出生日期
      const birthDate = idCard.slice(6, 14);
      if (!/^\d{8}$/.test(birthDate)) {
        return null;
      }
      const year = parseInt(birthDate.slice(0, 4));
      const month = parseInt(birthDate.slice(4, 6));
      const day = parseInt(birthDate.slice(6, 8));

      // 提取性别
      const genderCode = parseInt(idCard[16]);
      const gender = genderCode % 2 === 0 ? 2 : 1;

      // 返回生日和性别信息
      return {
        birthday: `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
        gender: gender
      };
    },
    // 转换为 18 位身份证号码
    convertTo18IdCard(idCard) {
      if (idCard.length === 18) {
        return idCard;
      }
      const century = new Date().getFullYear() % 100;
      const year = parseInt(idCard.slice(6, 8));
      let prefix = '';

      if (year <= century) {
        prefix = '20';
      } else {
        prefix = '19';
      }
      return idCard.slice(0, 6) + prefix + idCard.slice(6);
    },
    formatIdCard(e) {
      this.info.info[e].sportsmanCardNumber = this.info.info[e].sportsmanCardNumber.replace(/x/gi, 'X')
    },
    getcardnum(e) {
      if (this.info.info[e].sportsmanCardType == '01') {
        this.info.info[e].sportsmanBirthday = this.getBirthdayAndGender(this.info.info[e].sportsmanCardNumber).birthday
        this.info.info[e].sportsmanSex = this.getBirthdayAndGender(this.info.info[e].sportsmanCardNumber).gender
      }
    },
    preservation() {
      this.info.status = 0;
      const regex = /^1[3456789]\d{9}$/;
      let errorMessage = '';

      const hasError = this.info.info.some(item => {
        if (this.$verification(item.sportsmanName)) {
          errorMessage = '请输入姓名';
          return true;
        }
        if (this.$verification(item.sportsmanSex)) {
          errorMessage = '请选择性别';
          return true;
        }
        if (this.$verification(item.sportsmanCardType)) {
          errorMessage = '请选择证件类型';
          return true;
        }
        if (this.$verification(item.sportsmanCardNumber)) {
          errorMessage = '请输入证件号码';
          return true;
        }
        if (item.sportsmanCardType == '01') {
          if (!this.validateIdCard(item.sportsmanCardNumber)) {
            errorMessage = '证件号码格式错误';
            return true;
          }
          if (this.getBirthdayAndGender(item.sportsmanCardNumber).birthday != item.sportsmanBirthday) {
            errorMessage = '出生日期与身份证上不符合';
            return true;
          }
          if (this.getBirthdayAndGender(item.sportsmanCardNumber).gender != item.sportsmanSex) {
            errorMessage = '性别与身份证实际不符合';
            return true;
          }
        }
        if (this.$verification(item.sportsmanBirthday)) {
          errorMessage = '请选择出生日期';
          return true;
        }
        if (this.$verification(item.sportsmanPhone)) {
          errorMessage = '请输入手机号';
          return true;
        }
        if (!regex.test(item.sportsmanPhone)) {
          errorMessage = '手机号码格式错误';
          return true;
        }
        return false;
      });

      if (hasError) {
        this.$message.error(errorMessage);
        return;
      }

      // if (this.radio == 0) {
      //   this.$message.error('请同意免责协议');
      //   return;
      // }

      let data = JSON.parse(JSON.stringify(this.info));
      data.info.forEach(item => {
        item.sportsmanName = this.$encrypt(item.sportsmanName);
        item.sportsmanCardNumber = this.$encrypt(item.sportsmanCardNumber);
        item.sportsmanPhone = this.$encrypt(item.sportsmanPhone);
      });

      this.$api.submitUserEnroll(data).then(res => {
        this.$message.success('保存成功');
        this.handleClose();
      });

    },
    Submit() {
      this.info.status = 1;
      const regex = /^1[3456789]\d{9}$/;
      let errorMessage = '';

      const hasError = this.info.info.some(item => {
        if (this.$verification(item.sportsmanName)) {
          errorMessage = '请输入姓名';
          return true;
        }
        if (this.$verification(item.sportsmanSex)) {
          errorMessage = '请选择性别';
          return true;
        }
        if (this.$verification(item.sportsmanCardType)) {
          errorMessage = '请选择证件类型';
          return true;
        }
        if (this.$verification(item.sportsmanCardNumber)) {
          errorMessage = '请输入证件号码';
          return true;
        }
        if (item.sportsmanCardType == '01') {
          if (!this.validateIdCard(item.sportsmanCardNumber)) {
            errorMessage = '证件号码格式错误';
            return true;
          }
          if (this.getBirthdayAndGender(item.sportsmanCardNumber).birthday != item.sportsmanBirthday) {
            errorMessage = '出生日期与身份证上不符合';
            return true;
          }
          if (this.getBirthdayAndGender(item.sportsmanCardNumber).gender != item.sportsmanSex) {
            errorMessage = '性别与身份证实际不符合';
            return true;
          }
        }
        if (this.$verification(item.sportsmanBirthday)) {
          errorMessage = '请选择出生日期';
          return true;
        }
        if (this.$verification(item.sportsmanPhone)) {
          errorMessage = '请输入手机号';
          return true;
        }
        if (!regex.test(item.sportsmanPhone)) {
          errorMessage = '手机号码格式错误';
          return true;
        }
        return false;
      });

      if (hasError) {
        this.$message.error(errorMessage);
        return;
      }

      // if (this.radio == 0) {
      //   this.$message.error('请同意免责协议');
      //   return;
      // }

      let data = JSON.parse(JSON.stringify(this.info));
      data.info.forEach(item => {
        item.sportsmanName = this.$encrypt(item.sportsmanName);
        item.sportsmanCardNumber = this.$encrypt(item.sportsmanCardNumber);
        item.sportsmanPhone = this.$encrypt(item.sportsmanPhone);
      });

      this.$api.submitUserEnroll(data).then(res => {
        this.$message.success('报名成功');
        this.handleClose();
      });
    },
    handleClose() {
      this.info = {
        userId: this.$store.state.userid,
        matchId: "",
        settingsId: "",
        status: 0,
        info: []
      }
      this.radio = 0
      this.fileList = []
      let data = JSON.parse(localStorage.getItem('regsignup'))
      this.$router.replace({
        path: '/h5/registerIndex',
        query: {
          refresh: new Date().getTime(),
          menuIndex: 3,
        },
      })
      localStorage.removeItem('regsignup')
    },
    checkRoute(to) {
      // 假设我们要检查是否去往以下指定的路由
      const routeNames = ['H5registernewlyaddedlist', 'H5registernewlyadded', 'H5registerregistrationform'];

      // 判断新路由（to.name）是否在指定的路由名称列表中
      return routeNames.includes(to.name);
    },
    getcertificate(e, title) {
      this.$alert(e, '提示', {
        confirmButtonText: '关闭',
        callback: action => { }
      });
    },
    download(row) {
      this.$api.getFile({ objectName: row.filePath }).then((res) => {
        this.$convert(res.data.data.result.url).then((fileUrl) => {
          var newWindow = window.open('', '_blank');
          newWindow.location.href = fileUrl
        })
      })
    }
  },
  created() {
    this.getIdCardType()
    this.info.matchId = this.$route.query.matchId
    this.info.settingsId = this.$route.query.settingsId
    let obj = {
      projectId: this.$route.query.projectId,
      projectSettingId: this.$route.query.projectSettingId,
      groupSettingId: this.$route.query.groupSettingId,
      sportsmanName: '',
      sportsmanSex: '',
      sportsmanBirthday: '',
      sportsmanCardType: '01',
      sportsmanCardNumber: '',
      sportsmanPhone: '',
      fileList: [],
      projectRemark: '',
      sportsmanRemark: '',
      groupName: this.$route.query.groupName,
      projectName: this.$route.query.projectName,
      id: null
    }
    this.info.info.push({ ...obj });
    if (this.isChineseTextContains(this.$route.query.projectName, '双打')) {
      this.info.info.push({ ...obj });
    }
    this.getBasicEnrollInfoByMatchId(this.$route.query.matchId)
    this.userEnrollInfo(this.$route.query.id)
    this.groupName = this.$route.query.groupName
    this.projectName = this.$route.query.projectName
    this.disabled = this.$route.query.disabled == 'true' ? true : false
  },
  beforeRouteLeave(to, from, next) {
    if (this.checkRoute(to)) {
      next(); // 允许导航
    } else {
      localStorage.removeItem('regsignup')
      // 可以阻止导航或弹出提示
      next();
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.$verification(JSON.parse(localStorage.getItem('regsignup')))) {
        let data = JSON.parse(localStorage.getItem('regsignup'))
        if (data.distinguish === 2) {
          this.$nextTick(() => {
            this.info = data.data
            Vue.set(this.info.info[data.index], 'sportsmanName', data.new.data.name)
            Vue.set(this.info.info[data.index], 'sportsmanSex', data.new.data.sex.toString())
            Vue.set(this.info.info[data.index], 'sportsmanBirthday', data.new.data.birthday)
            Vue.set(this.info.info[data.index], 'sportsmanCardType', data.new.data.certificateType)
            Vue.set(this.info.info[data.index], 'sportsmanCardNumber', data.new.data.idCard)
            Vue.set(this.info.info[data.index], 'sportsmanPhone', data.new.data.phone)
          });
        }
      }
    }, 500);
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
}
</script>

<style lang='scss' scoped>
.top {
  display: flex;
  justify-content: space-between;
  margin: 20PX 40PX;

  .titlebox {
    display: flex;
    align-items: center;

    .xian {
      width: 5PX;
      height: 15PX;
      background: #3E79CB;
      border-radius: 3PX;
    }

    .title {
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 15PX;
      margin-left: 10PX;
      color: #353333;
      background: linear-gradient(150deg, #2F6BBF 0.146484375%, #7FB5FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.inputbox {
  display: flex;
  align-items: center;
  height: 41PX;
  margin: 24PX 35PX 0;
  background: #F1F6FF;
  border-radius: 50PX;

  img {
    width: 10PX;
    height: 16PX;
    margin-left: 16PX;
  }

  p {
    font-size: 12PX;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 19PX;
    flex-shrink: 0;
    margin-left: 9PX;
  }

  .Remark {
    font-size: 12PX;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 19PX;
    flex-shrink: 0;
    margin-left: 9PX;
    min-width: 60PX;
    max-width: 60PX;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    font-size: 13PX;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #E90709;
    flex-shrink: 0;
    margin-left: 4PX;
  }

  ::v-deep .el-input__inner {
    border: none;
    background: #F1F6FF;
    border-radius: 50PX;
  }

  .inputboxbut {
    flex-shrink: 0;
    margin-right: 11PX;
    padding: 5PX 10PX;
    font-size: 12PX;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 19PX;
    background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
    border-radius: 50PX;
  }

  .but {
    width: 100PX;
    height: 31PX;
    border-radius: 30PX;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, #1F6EEE, #60B8FF);
    flex-shrink: 0;
    margin-right: 5PX;
  }

  ::v-deep .el-select .el-input,
  ::v-deep .el-select-dropdown .el-select-dropdown__item.selected {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  ::v-deep input::-webkit-input-placeholder {
    color: #333333;
  }
}

.xians {
  width: 100%;
  height: 8PX;
  background: #D8DDE6;
  opacity: 0.28;
  margin-top: 24PX;
}

::v-deep .ant-select-selection--single {
  width: 180PX;
}

::v-deep .ant-select-arrow {
  display: none !important;
}

::v-deep .el-input__icon {
  display: none;
}

::v-deep .el-input--prefix .el-input__inner {
  padding-left: 15PX;
}

.el-button--primary {
  background: #348AFE;
  border-color: #348AFE;
  border-radius: 20px;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #0066FF;
  background: #0066FF;
}

::v-deep .el-radio__inner {
  width: 13PX;
  height: 13PX;
}

::v-deep .el-radio__label {
  display: none;
}

.radio {
  display: flex;
  align-items: center;
  margin-bottom: 30PX;
  margin-left: 51PX;
  margin-top: 24PX;
}

.radio p {
  font-size: 12PX;
  font-weight: 400;
  color: #333333;
}

.radio a {
  color: #0066FF;
}

::v-deep .el-radio {
  margin-right: 9PX;
}

.button {
  display: flex;
  justify-content: center;

  .save {
    width: 100%;
    height: 45PX;
    background-image: linear-gradient(to bottom, #6FB2FF, #095FFF);
    font-size: 15PX;
    border-radius: 50PX;
    font-weight: bold;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 35PX 0;
  }
}


.button2 {
  display: flex;
  justify-content: center;
  margin: 30PX 0;
  padding-bottom: 30PX;

  .save {
    width: 100%;
    height: 45PX;
    background-image: linear-gradient(to bottom, #D8E2EB, #C2D0E6);
    font-size: 15PX;
    border-radius: 50PX;
    font-weight: bold;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 35PX 0;
  }
}
</style>